import "idempotent-babel-polyfill";
// import LazyLoad from "vanilla-lazyload";
import {smoothScroll} from "./modules/smoothScroll";
import {tabOffer} from "./modules/tabOffer";
// import {toTopButton} from "./modules/toTopButton";

//lazy loading
// const lazyLoadInstance = new LazyLoad({
    // your custom settings go here
// });
// lazyLoadInstance.update();

//async loading for not important JS files
document.addEventListener("DOMContentLoaded", () => {
    smoothScroll();
    tabOffer();
    // toTopButton();
});
